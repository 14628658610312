import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "output"]

  initialize () {

  }

  toggleNavigation() {
    let nav = document.querySelector('header .nav');

    if (nav.classList.contains('visible') == true) {
      nav.classList.remove('visible');
    } else {
      nav.classList.add('visible');
    }
  }

  toggleUserMenu() {
    let user_menu = document.querySelector('header .user-menu');

    if (user_menu.classList.contains('visible') == true) {
      user_menu.classList.remove('visible');
    } else {
      user_menu.classList.add('visible');
    }
  }
  
}
