import { Controller } from "stimulus";
import {MDCSelect} from '@material/select';
import {MDCCheckbox} from '@material/checkbox';


export default class extends Controller {
  static targets = [ "output" ]

  connect () {
    let _this = this;
    
    window.mdcAutoInit();
  }

  httpValidation(event) {
    var value;

    value = event.currentTarget.value;

    if (value && !value.match(/^http([s]?):\/\/.*/)) {
      event.currentTarget.value = 'http://' + value;
    }
  }

  subscribeValidation(event) {

    const checkboxElement = document.querySelector('.subscribe .mdc-checkbox'); 
    let checkbox = checkboxElement.MDCCheckbox;

    if (checkbox.checked == true) {
      document.querySelector('.subscribe input.submit').click();

    } else {
      event.preventDefault();
      event.stopPropagation();
      checkboxElement.classList.add('red');
      document.querySelector('.subscribe .subscribe-label').classList.add('red');
    }

  }


}
