import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ ]

  initialize () {

  }

  downloadPdf(event) {
    let link = event.currentTarget;
    let downloadIcon = link.querySelector('.download-icon'); 
    let spinnerIcon = link.querySelector('.spinner-icon');
    let doneIcon = link.querySelector('.done-icon');

    downloadIcon.remove(); 
    spinnerIcon.classList.remove('hidden');

    setTimeout(function() {
      link.href = "";
      spinnerIcon.remove();
      doneIcon.classList.remove('hidden');
    }, 5000);
  }

}
