import { Controller } from "stimulus"
import {MDCChip, MDCChipSet, MDCChipActionType} from '@material/chips';
import Rails from "@rails/ujs";

var placeholder = "<div class='field-placeholder'><input type='text' name='tag_placeholder' value='' required='required'></div>";

export default class extends Controller {
	static targets = [ "tags", "form" ]

	initialize() {
		let _this = this;
		const chipSetElements = this.formTarget.querySelectorAll('.mdc-evolution-chip-set');
		var tagsForm = this.formTarget.querySelector("#form_tags");

		chipSetElements.forEach((chipSetEl) => {
			const chipSet = new MDCChipSet(chipSetEl);

			chipSet.chips.forEach((chip, index) => {
				if (chip.root.getAttribute("record") !== null) {
					chipSet.setChipSelected(index, MDCChipActionType.PRIMARY, true);
				}
			});

			console.log("Required:", tagsForm.classList.contains('required'));
			console.log("Chipset selected:", chipSet.getSelectedChipIndexes().size);


			if (tagsForm.classList.contains('required') && chipSet.getSelectedChipIndexes().size == 0)
				tagsForm.innerHTML = placeholder;

			chipSet.listen('MDCChipSet:selection', (ev) => {
				if ( chipSetEl.classList.contains("with-half-opacity") ) {
					if (chipSet.getSelectedChipIndexes().size > 0) {
						chipSet.chips.forEach((chip, index) => {
							chip.root.classList.add("half-opacity");
						});
					} else {
						chipSet.chips.forEach((chip, index) => {
							chip.root.classList.remove("half-opacity");
						});
					}
				}
				let obj = _this.data.get("obj");
				setTimeout(function () {_this.chipsToInputs(chipSet, tagsForm, obj, _this.formTarget);}, 500);

			});

		});

	}

	chipsToInputs(chipSet, tagsForm, type, form) {
		console.log("selected:", chipSet.getSelectedChipIndexes());

		let _this = this;

		tagsForm.innerHTML = "";

		if ( chipSet.root.classList.contains("picture-dependent") && chipSet.getSelectedChipIndexes().size > 0) {
			var pictureEl = document.querySelector("#category-picture");

			if (pictureEl != null) {

				for (let i = pictureEl.classList.length - 1; i >= 0; i--) {
					const className = pictureEl.classList[i];
					if (className.startsWith('category-'))
						pictureEl.classList.remove(className);
				}

				pictureEl.classList.add('category-colored-' + chipSet.chips[chipSet.getSelectedChipIndexes().values().next().value].root.dataset.picture)
			}

		}

		for (var i = chipSet.chips.length - 1; i >= 0; i--) {
			var chipTagId = chipSet.chips[i].root.getAttribute("record");
			var chipId = chipSet.getChipIdAtIndex(i);

			if ((chipTagId !== null) && !chipSet.chips[i].isActionSelected(MDCChipActionType.PRIMARY)) {
				
				tagsForm.insertAdjacentElement('beforeEnd', this.tagHTML("1", "_destroy", i, type));
				tagsForm.insertAdjacentElement('beforeEnd', this.tagHTML(chipTagId, "id", i, type));

			} else if (chipSet.chips[i].isActionSelected(MDCChipActionType.PRIMARY) && (chipTagId === null)) {

				tagsForm.insertAdjacentElement('beforeEnd', this.tagHTML(chipId, "tag_id", i, type));
			}

		};



		if (tagsForm.classList.contains('required') && chipSet.getSelectedChipIndexes().size == 0)
			tagsForm.innerHTML = placeholder;
		else
			Rails.fire(form, 'submit');
	}

	tagHTML(value, attr, position, obj) {
		var input = document.createElement("input");
		input.type = "hidden";
		input.name = `${obj}[tag_records_attributes][${position}][${attr}]`;
		input.value = value;

		return input;
	}

}


