import { Controller } from "stimulus";
import Rails from '@rails/ujs';
import {MDCSnackbar} from '@material/snackbar';

const listHidden = document.querySelector('.list-hidden')

const getMouseOffset = (evt) => {
  const targetRect = evt.target.getBoundingClientRect()
  const offset = {
    x: evt.pageX - targetRect.left,
    y: evt.pageY - targetRect.top
  }

  return offset
}

const getElementVerticalCenter = (el) => {
  const rect = el.getBoundingClientRect()
  return rect.top//(rect.bottom - rect.top) / 2
}

export default class extends Controller {
  static targets = [ "output" ]

  connect () {
    let _this = this;
    window.mdcAutoInit();

    let list = document.querySelector('.steps')
    let practice_id = this.data.get("practiceid")
    let originalIDs = []

    list.querySelectorAll('.white-card').forEach(function (itemEl) {
      originalIDs.push(itemEl.dataset.id)
    });

    this.sortable(list, function (item) {
      let ids = []
      let listItems = list.querySelectorAll('.white-card')

      listItems.forEach(function (itemEl) {
        ids.push(itemEl.dataset.id)
      });

      if (!ids.every((v,i)=> v === originalIDs[i])) {
        _this.updateOrder(ids, practice_id)
        originalIDs = ids
      }

    })
  }

  updateOrder(ids, practice_id) {
    const data = {new_order: ids}

    fetch("/" + window.locale + "/practices/" + practice_id + ".js", {
      method: 'PUT',
      mode: 'same-origin', // no-cors, *cors, same-origin
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken()
      },
      body: JSON.stringify(data),
    })
    .then((response) => response.text())
    .then((body) => {


      const div = document.createElement('div');
      div.innerHTML = body;
      const snackbarEl = div.firstElementChild;
      document.body.appendChild(snackbarEl)

      let snackbar = new MDCSnackbar(snackbarEl)
      //snackbar.open()

      //
      //console.log('Success:', body);
    })
    .catch((error) => {
      //console.error('Error:', error);
    });
  }

  sortable(rootEl, onUpdate) {
     var dragEl;
     
     // Function responsible for sorting
     function _onDragOver(evt) {
       evt.preventDefault();
       evt.dataTransfer.dropEffect = 'move';
       var target = evt.target;
      //console.log("dragEl.draggable: ", dragEl.draggable)
      //console.log("target.draggable: ", target.draggable)

       if (target && target !== dragEl && dragEl.classList.contains("white-card") && target.classList.contains("white-card")) {
          // Sorting
          const offset = getMouseOffset(evt)
          const middleY = getElementVerticalCenter(dragEl)

          //console.log("_onDragOver: ",offset.y, evt.pageY - middleY )
          
          if (offset.y > (evt.pageY - middleY)) {
            //console.log(">>> top")
            
            rootEl.insertBefore(dragEl, target)
          } else {
            
            //console.log(">>> bottom")
            rootEl.insertBefore(dragEl, target.nextSibling)
          }
       }
     }
     
     // End of sorting
     function _onDragEnd(evt){
         evt.preventDefault();
        
         dragEl.classList.remove('ghost');
         rootEl.removeEventListener('dragover', _onDragOver, false);
         rootEl.removeEventListener('dragend', _onDragEnd, false);


         // Notification about the end of sorting
         onUpdate(dragEl);
     }
     
     // Sorting starts
     rootEl.addEventListener('dragstart', function (evt){
         dragEl = evt.target; // Remembering an element that will be moved
         
         // Limiting the movement type
         evt.dataTransfer.effectAllowed = 'move';
         evt.dataTransfer.setData('Text', dragEl.textContent);


         // Subscribing to the events at dnd
         rootEl.addEventListener('dragover', _onDragOver, false);
         rootEl.addEventListener('dragend', _onDragEnd, false);

         setTimeout(function () {
             // If this action is performed without setTimeout, then
             // the moved object will be of this class.
             dragEl.classList.add('ghost');
         }, 0)
     }, false);
  }
}