import { Controller } from "stimulus";
import {MDCMenu, DefaultFocusState} from '@material/menu';
import {MDCList} from '@material/list';
import {MDCTextField} from '@material/textfield';


let menu,list,listElement, target = null;
let inFocus = false;

export default class extends Controller {
  static targets = [ ]

  initialize () {
    let _this = this;
    window.mdcAutoInit();

    const menuElement = document.querySelector("#tag-menu"); 

    menu = new MDCMenu(menuElement);


    menu.listen('MDCMenu:selected', (ev) => {
      console.log(ev.detail.item.innerText);
      target.value = ev.detail.item.innerText;
    });


    document.querySelector("#tags-text-field input").addEventListener('keydown', (e) => {
      if ( (e.code == 'ArrowDown') && !inFocus && menu.open == true) {
        menu.items[0].focus();
        inFocus = true;
      } 
    });
  }

  getTagList(e) {
    target = event.currentTarget;
    
    let params = new URLSearchParams({
      s: event.currentTarget.value,
      t: target.dataset.type
    });

    fetch("/" + window.locale + "/tags/autocomplete?" + params)
    .then(response => response.text())
    .then(function(text) {
      if (text.length > 1) {
        menu.list.root.innerHTML = text;
        menu.open = true;
      } else {
        menu.open = false;
        inFocus = false;
        menu.list.root.innerHTML = "";
      }
      
    });
  }


}
