import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "submit", "output", "addr", "city", "country", "lat", "lon", "countryshort", "input", "map", "name"]

  connect() {

    let _this = this;
    window.loadingGoogleApi = window.loadingGoogleApi || getScript("https://maps.googleapis.com/maps/api/js?v=3.0&libraries=places&language=en&key=AIzaSyA8shTcPIlcYu5cUZKSNOpIlIkP3qZERIw&callback=dispatchMapsEvent");
    window.loadingGoogleApi.then(() => {initSearch(_this.inputTarget, _this)});

  }

  inputHandler(event) {
    if (event.key == "Enter") { event.preventDefault() }
  }

}


function setResults(place, targets) {
  let result = "";

  let formatted_address = "";
  let street = "";
  let street_number = "";
  let city = "";
  let country = "";
  let country_short = "";
  let lat = "";
  let lon = "";
  let name = "";

  place.address_components.forEach(function(item) {
    if(item.types.includes("locality"))
      city = item.long_name

    if(item.types.includes("administrative_area_level_3"))
      city = item.short_name

    if(item.types.includes("country")) {
      country = item.long_name
      country_short = item.short_name
    }

    if(item.types.includes("route"))
      street = item.long_name

    if(item.types.includes("street_number"))
      street_number = item.long_name


  });

  formatted_address = place.formatted_address;
  lat = place.geometry.location.lat();
  lon = place.geometry.location.lng();

  result = `Address ${street} ${street_number}, ${city}, ${country} - ${lat}:${lon} - [${formatted_address}]`;


  //Init form
  targets.addrTarget.value = street + " " + street_number;
  targets.cityTarget.value = city;
  targets.countryTarget.value = country;
  targets.latTarget.value = lat;
  targets.lonTarget.value = lon;
  targets.countryshortTarget.value = country_short;
  //targets.nameTarget.value = place.name;

  return result;
}

function initSearch(input, targets) {
  let searchBox = new google.maps.places.SearchBox(input, { types: ['(continent)']});

  searchBox.addListener('places_changed', function() {
    
    var places = searchBox.getPlaces();

    var lon, lat, addr, city, country;

    addr = city = country = "";
    
    if (places.length == 0) {
      return;
    }

    places.forEach(function(place) {
      setResults(place, targets)
    });
  });
}

const getScript = url => new Promise((resolve, reject) => {
  const script = document.createElement('script')
  script.src = url
  script.async = true

  script.onerror = reject

  script.onload = script.onreadystatechange = function() {
    const loadState = this.readyState

    if (loadState && loadState !== 'loaded' && loadState !== 'complete') return

    script.onload = script.onreadystatechange = null

    resolve()
  }

  document.head.appendChild(script)
})
