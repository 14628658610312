import { Controller } from "stimulus"
import {MDCChip, MDCChipSet, MDCChipActionType} from '@material/chips';
import Rails from "@rails/ujs";

let placeholder = "<div class='field-placeholder'><input type='text' name='tag_placeholder' value='' required='required'></div>";
let chipSet = null

export default class extends Controller {
  static targets = [ "tags", "form", "template", "input", "output", "chipset"]
  static values = { type: String }

  initialize() {
    let _this = this;
    let chipSet = this.chipsetTarget.MDCChipSet;
    var tagsForm = this.outputTarget;

    chipSet.chips.forEach((chip, index) => {
      if (chip.root.getAttribute("record") !== null) {
        chipSet.setChipSelected(index, MDCChipActionType.PRIMARY, true);
      }
    });

    if (tagsForm.classList.contains('required') && chipSet.getSelectedChipIndexes().size == 0)
      tagsForm.innerHTML = placeholder;

    chipSet.listen('MDCChipSet:selection', (ev) => {
      if ( this.chipsetTarget.classList.contains("with-half-opacity") ) {
        if (chipSet.getSelectedChipIndexes().size > 0) {
          chipSet.chips.forEach((chip, index) => {
            chip.root.classList.add("half-opacity");
          });
        } else {
          chipSet.chips.forEach((chip, index) => {
            chip.root.classList.remove("half-opacity");
          });
        }
      }
      let obj = this.formTarget.dataset.type;
      //console.log("chipSet.chips: initialize: size", chipSet.chips.length);
      setTimeout(function () {_this.chipsToInputs(chipSet, tagsForm, obj);}, 500);

    });
  }

  onPostSuccess(e) {
    let _this = this;
    const [_data, _status, xhr] = event.detail
    console.log("onPostSuccess: ", xhr.response)
    let newTag = JSON.parse(xhr.response)

    let tagsForm = this.outputTarget;

    let obj = this.formTarget.dataset.type;

    let chipSet = this.chipsetTarget.MDCChipSet;
    //console.log("obj:", obj);

    let chipEl = document.createElement( 'div' );
    chipEl.innerHTML = this.templateTarget.outerHTML.replace("#tagName", newTag.tag.name).replace("#tagID", newTag.tag.id);

    let ids = []

    chipSet.chips.forEach((_chip) => { ids.push(_chip.getElementID() ) });

    if (!ids.includes(newTag.tag.id.toString())) {
      chipSet.root.children[0].appendChild(chipEl)
      let chip = new MDCChip(chipEl.firstElementChild)
      chipSet.chips.push(chip);
      chipSet.setChipSelected((chipSet.chips.length - 1), MDCChipActionType.PRIMARY, true);

      this.inputTarget.MDCTextField.value = ""
      this.inputTarget.MDCTextField.focus()

    } else {
      const isEq = (element) => element == newTag.tag.id.toString();
      let chipIndex = ids.findIndex(isEq);

      chipSet.setChipSelected(chipIndex, MDCChipActionType.PRIMARY, true);
    }

    console.log("chipSets.chips:", chipSet.chips.length)

    setTimeout(function () {_this.chipsToInputs(chipSet, tagsForm, obj);}, 500);
  }

  chipsToInputs(_chipSet, tagsForm, type) {
    console.log("selected:", _chipSet.getSelectedChipIndexes());
    
    tagsForm.innerHTML = "";

    for (var i = _chipSet.chips.length - 1; i >= 0; i--) {
      var chipTagId = _chipSet.chips[i].root.getAttribute("record");
      var newTag = JSON.parse(_chipSet.chips[i].root.getAttribute("new"));
      var chipId = _chipSet.getChipIdAtIndex(i);

      if ((chipTagId !== null) && !_chipSet.chips[i].isActionSelected(MDCChipActionType.PRIMARY)) {
        
        tagsForm.insertAdjacentElement('beforeEnd', this.tagHTML("1", "_destroy", i, type));
        tagsForm.insertAdjacentElement('beforeEnd', this.tagHTML(chipTagId, "id", i, type));

      } else if (_chipSet.chips[i].isActionSelected(MDCChipActionType.PRIMARY) && newTag ) {

        tagsForm.insertAdjacentElement('beforeEnd', this.tagHTML(chipId, "tag_id", i, type));

      }

    };

    if (tagsForm.classList.contains('required') && _chipSet.getSelectedChipIndexes().size == 0)
      tagsForm.innerHTML = placeholder;

  }

  tagHTML(value, attr, position, obj) {
    var input = document.createElement("input");
    input.type = "hidden";
    input.name = `${obj}[tag_records_attributes][${position}][${attr}]`;
    input.value = value;

    return input;
  }
}