import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output"]


  initialize() {
    if (localStorage.getItem('consentMode') === null) {
      document.getElementById("cookies_popup").style.display = "flex";
    }
  }

  acceptAllCookies() {   
    let _this = this;

    _this.setConsent({
        necessary: true,
        analytics: true,
        preferences: true,
        marketing: true
    });

    _this.hideBanner();
  }

  acceptNecessaryCookies() {   
    let _this = this;

    _this.setConsent({
        necessary: true,
        analytics: true,
        preferences: true,
        marketing: false
    });

    _this.hideBanner();
  }

  setConsent(consent) {

    const consentMode = {
        'functionality_storage': consent.necessary ? 'granted' : 'denied',
        'security_storage': consent.necessary ? 'granted' : 'denied',
        'ad_storage': consent.marketing ? 'granted' : 'denied',
        'ad_user_data': consent.marketing ? 'granted' : 'denied',
        'ad_personalization': consent.marketing ? 'granted' : 'denied',
        'analytics_storage': consent.analytics ? 'granted' : 'denied',
        'personalization_storage': consent.preferences ? 'granted' : 'denied'
    };
    gtag('consent', 'update', consentMode);
    localStorage.setItem('consentMode', JSON.stringify(consentMode));
  }

  hideBanner() {
    document.getElementById("cookies_popup").style.display = "none";
  }

}
