import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require("controllers")
require("packs/material")
//require("packs/direct_uploads")

Rails.start()
ActiveStorage.start()

import "../styles/media";

import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

import { RecaptchaVerifier, getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCHB5myPu7VhVtBUcreApESB7LsoVoJLWI",
  authDomain: "faved-bca99.firebaseapp.com",
  databaseURL: "https://faved-bca99-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "faved-bca99",
  storageBucket: "faved-bca99.appspot.com",
  messagingSenderId: "966638381717",
  appId: "1:966638381717:web:f078ce2b1059f2bdac099c",
  measurementId: "G-SYXM4LS8M9"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();

window.locale = 'en';


document.addEventListener("DOMContentLoaded", function(){

  const smoothButtons = document.querySelectorAll(".smooth-button");

  for (const btn of smoothButtons) {
    btn.addEventListener("click", clickSmoothButton);
  }

});

function clickSmoothButton(e) {
  e.preventDefault();
  const href = this.getAttribute("href");
  const offsetTop = document.querySelector(href).offsetTop - 16;

  scroll({
    top: offsetTop,
    behavior: "smooth"
  });
}

window.onload = function() {
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider("6Le_wnwcAAAAAH0Qv5yHR4HXWURDEtSXmXZTS-3V"),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
  });
  
  if (document.querySelector('#recaptcha-container') != null)
    window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {}, auth);
}