import { Controller } from "stimulus";
import Rails from "@rails/ujs";

let searchResultsEl;
let timer;

export default class extends Controller {
  static targets = [ "input", "result"]

  initialize() {  

  }

  input(e) {
    let key = this.inputTarget.value.toLowerCase().trim();
    let _this = this;

    clearTimeout(timer);
      
    timer = setTimeout(() => {
      _this.startSearch(key);
    }, 200);
  }

  startSearch(key) {
    if (key.length > 1) {
      if (this.resultTarget.innerHTML.length == 0) {
        //$("#search-loader").show();
      }
      this.getSearchResults(key);
    } else {
      this.resultTarget.innerHTML = "";
      //$("#search-loader").hide();
    }
  }
  

  getSearchResults(key) {
    let _this = this;

    fetch("/" + window.locale + "/search?s=" + key)
    .then((response) => response.text())
    .then((body) => {
      let current_key = _this.inputTarget.value.toLowerCase().trim();

      if (current_key == key) {
        this.resultTarget.innerHTML = body
      }
    })
  }


      
      
}

