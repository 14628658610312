import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "output", "viewRepliesButton", "showReplyFormButton", "commentField", "submitCommentButton"]

  initialize () {
    let _this = this;

    this.viewRepliesButtonTargets.forEach((btn) => {
      btn.addEventListener("click", function(event) {
        event.currentTarget.parentNode.querySelector('.replies').style.display = 'block';

        let form = event.currentTarget.parentNode.querySelector('form');
        if (form != null) {
          form.classList.remove("hidden");
        }

        event.currentTarget.remove();
        
      });
    });

    this.showReplyFormButtonTargets.forEach((btn) => {
      btn.addEventListener("click", function(event) {
        let form = event.currentTarget.parentNode.parentNode.querySelector('form');
        if (form != null) {
          form.classList.remove("hidden");
          form.querySelector('textarea').focus();
        }
      });
    });

    this.commentFieldTargets.forEach((field) => {

      field.querySelector('textarea').addEventListener("input", function(event) {
        this.style.height = "auto";
        this.style.height = (this.scrollHeight) + "px";
      });

      field.querySelector('textarea').addEventListener("focus", function(event) {
        let _input = this;
        _input.style.height = "auto";
        _input.style.height = (this.scrollHeight) + "px";
        
        _this.submitCommentButtonTarget.style.display = "inline-flex";
      });

      field.querySelector('textarea').addEventListener("blur", function(event) {
        if (this.value.length < 1) {
          let form = this.closest('form');

          if (form.classList.contains("reply-form"))
            form.classList.add("hidden");
          _this.submitCommentButtonTarget.style.display = "none";
        }
      });
    });
  }

}
