import { Controller } from "stimulus";
import { DirectUpload } from "@rails/activestorage"
import * as ActiveStorage from "@rails/activestorage"
import {MDCCircularProgress} from '@material/circular-progress';

const FILES_LIMIT = 5

export default class extends Controller {
  static targets = [ "files", "form", "errors" ]

  connect () {
    let _this = this;
    //const materialForm = this.formTarget;
    //let filesForm = this.filesTarget;
    let files = [];
    
    window.mdcAutoInit();

    this.initDU();
    this.initDeleteButtons(); 
    setTimeout(() => {_this.initDUEvents();}, 1000);

    document.addEventListener("dragover", function(event) {
      event.preventDefault();
    });
    
  }

  initDeleteButtons() {
    const deleteButtons = document.querySelectorAll('#image-preview .image .delete');

    deleteButtons.forEach((deleteBtn) => {
        
      deleteBtn.addEventListener('click', function(event) {
        this.parentElement.style.display = 'none';
        let checkbox = this.parentElement.querySelector('input[type=checkbox]');
        let input = this.parentElement.querySelector('input[type=hidden]');

        if (checkbox != null)
          checkbox.checked = true;

        if (input != null)
          input.remove();
      });
    });
  }

  initDU () {
    let _this = this;
    const imageTemplate = document.querySelector('#image-template');
    const input = document.querySelector('input[type=file]');
    const form = document.querySelector('#stepform');
    const uploader = document.querySelector('#image-uploader');
    const preview = document.querySelector('#image-preview');
    const uploadButton = document.querySelector('#upload-btn'); 

    let uploadedMaterialsCount = 0;
    let uploadedMaterialsLastIndex = 0;
    // Bind to normal file selection
    uploader.addEventListener('drop', addFiles, false);
    input.addEventListener('change', addFiles, false);

    uploadButton.addEventListener('click', function(event) {
      input.click();
    });
    
    function addFiles (event) {
      event.preventDefault()
      event.stopPropagation()

      uploadedMaterialsCount = form.querySelectorAll('.image:not([style*="display: none;"]):not([id="image-template"])').length;
      
      let dt;

      if (event.dataTransfer !== undefined)
        dt = event.dataTransfer
      else
        dt = input

      _this.errorsTarget.innerHTML = "";

      Array.from(dt.files).forEach((file, index) => {

        //VALIDATIONS
        if (!file.type.match('image.jpg|image.png|image.jpeg')) {
          _this.errorsTarget.innerHTML = "Only JPG or PNG.";
          return
        }

        if (file.size > 10 * 1024 * 1024){
          _this.errorsTarget.innerHTML = "Max 10Mb.";
          return
        }

        console.log("addFiles:", uploadedMaterialsCount, "index:", index)

        if (((index + 1) + uploadedMaterialsCount) > FILES_LIMIT){
          _this.errorsTarget.innerHTML = "Max 5 images."; 
          return
        }
        //END VALIDATIONS

        let imageElement = imageTemplate.cloneNode(true);
        imageElement.id = index;

        const progressElement = imageElement.querySelector('.mdc-circular-progress');
        let progressBar = new MDCCircularProgress(progressElement);
        
        preview.append(imageElement);

        let newFile = new File([file], file.name.replace(/[^a-zA-Z0-9.]/g, "_"), {type: file.type})

        uploadFile(newFile, index, imageElement, progressBar);
      })
      // you might clear the selected files from the input
      input.value = null;
    }

    const uploadFile = (file, index, imgElement, progBar) => {
      progBar.progress = 0.0;
      // your form needs the file_field direct_upload: true, which
      //  provides data-direct-upload-url, data-direct-upload-token
      // and data-direct-upload-attachment-name

      let imgs = Array.from(form.querySelectorAll('input[name*=materials_attributes]:not([id=step_materials_attributes__id_image])'))
      let indexArray = []
      imgs.forEach((el) => {
        let m = el.name.match(/(\d+)/)

        if (m !== null) {
          console.log(el.name.match(/(\d+)/)[0])
          indexArray.push(el.name.match(/(\d+)/)[0])
        }
      });

      let maxIndex = 0

      if (indexArray.length > 0)
        maxIndex = Math.max(...indexArray)

      function directUploadWillStoreFileWithXHR(request) {
        request.upload.addEventListener("progress",
          event => directUploadDidProgress(event))
      }

      function directUploadDidProgress(event) {
        // Use event.loaded and event.total to update the progress bar
        progBar.progress = event.loaded/event.total;
      }

      const url = input.dataset.directUploadUrl
      const token = input.dataset.directUploadToken
      const attachmentName = input.dataset.directUploadAttachmentName
      const upload = new DirectUpload(file, url, { directUploadWillStoreFileWithXHR: directUploadWillStoreFileWithXHR })

      upload.create((error, blob) => {
        if (error) {
          // Handle the error
        } else {
          // Add an appropriately-named hidden input to the form with a
          //  value of blob.signed_id so that the blob ids will be
          //  transmitted in the normal upload flow
          const imageDiv = document.createElement('div');
          const hiddenField = document.createElement('input');

          
          imgElement.style.backgroundImage = "url(/rails/active_storage/blobs/" + blob.signed_id + "/" + blob.filename + ")";
          progBar.close();

          hiddenField.setAttribute("type", "hidden");
          hiddenField.setAttribute("value", blob.signed_id);
          hiddenField.name = input.name.replace('#id', index + maxIndex + 1);
          imgElement.appendChild(hiddenField);
          _this.initDeleteButtons(); 
          console.log("generated input: ", hiddenField);
        }
      })
    }

  }

  initDUEvents() {
    const form = document.querySelector('#stepform')
  }
}
