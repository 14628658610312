import { Controller } from "stimulus";
import { MDCSnackbar } from '@material/snackbar';
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "snackbar"]

  initialize() {  

    this.snackbarTargets.forEach((snackbarEl) => {
      const snackbar = new MDCSnackbar(snackbarEl);
      
      snackbar.open();

      snackbar.listen('MDCSnackbar:opened', () => {
        console.log("Snackbar: is opened ");
      });

      snackbar.listen('MDCSnackbar:closing', function() {
        console.log("Snackbar: is closed ");
        snackbar.root.remove();
      });
    });

  }

}

