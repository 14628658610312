import { Controller } from "stimulus"
import ENV from "packs/env.js.erb";
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from '!mapbox-gl';
import { countriesBounds } from "../packs/countries_bounds";

import { MDCDialog } from '@material/dialog';

var countries_bounds = countriesBounds();

var map,
    searchValue = "";

var bounds = [
    [-170,-57], // Southwest coordinates
    [190, 78] // Northeast coordinates
];

var searchTimer;

export default class extends Controller {
  static targets = ["map", "search"]

  static values = {url: String}

  initialize() {

  }


  getMapGrid() {
    this.mapDataRequest();
  }

  searchHandler() {
    var _this = this;

    if (_this.searchTarget.value.length > 2){
      searchValue = _this.searchTarget.value;

      clearTimeout(searchTimer);
      searchTimer = setTimeout(() => {
        _this.getMapGrid();
      }, 1000);
    } else {
      searchValue = "";

      if (_this.searchTarget.value.length == 0) {
        _this.getMapGrid();
      }

    }
  }

  resetSearch() {
    this.searchTarget.value = "";
    searchValue = "";
    this.getMapGrid();
  }

  getGrid(type, pg) {
    var cardAPI = "/" + window.locale + "/map";
    let _this = this;

  }

  mapDataRequest() {
    fetch("/" + window.locale + "/map?type=")
    .then(response => response.json())
    .then(data => map.getSource('objects').setData(data));
  }

  initMainMapWithDelay(event) {
    let _this = this;
    console.log("initMainMapWithDelay")
    setTimeout( function() { _this.initMainMap(event); }, 300); 
  }

  initMainMap(event) {
    let _this = this;
    let mapstyle = 'mapbox://styles/hundred-org/cltczb52w001801qpgwmeatq2?refresh=true';

    if (this.mapTarget.dataset.mapstyle !== undefined) {
      console.log(this.mapTarget.dataset.mapstyle);
      mapstyle = 'mapbox://styles/mapbox/' + this.mapTarget.dataset.mapstyle;
    }

    mapboxgl.accessToken = ENV.mapboxKey();
    map = new mapboxgl.Map({
      container: 'map',
      style: mapstyle,
      //center: [10.400000, 43.716667],
      zoom: -1,
      maxZoom: 9,
      maxBounds: bounds,
      attributionControl: false
    });

    map.addControl(new mapboxgl.AttributionControl({
      compact: true
    }));

    map.scrollZoom.disable();
    map.addControl(new mapboxgl.NavigationControl());
    
    map.on('load', function() {

      map.addSource('objects', {
        type: 'geojson',
        data: "/" + window.locale + "/map?type=",
        cluster: true,
        clusterMaxZoom: 10, // Max zoom to cluster points on
        clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
        clusterProperties: {
        // keep separate counts for each category in a cluster
        }
      });

      map.addLayer(
        {
          id: 'country-boundaries',
          source: {
            type: 'vector',
            url: 'mapbox://mapbox.country-boundaries-v1',
          },
          'source-layer': 'country_boundaries',
          type: 'fill',
          paint: {
            'fill-color': '#000',
            'fill-opacity': 0.1,
          },
        }
      );

      map.setFilter('country-boundaries', false);

      Promise.all(
          [
            {url: '/cluster-plum.png', id: "plum"},
          ].map(img => new Promise((resolve, reject) => {
              map.loadImage(img.url, function (error, res) {
                  map.addImage(img.id, res)
                  resolve();
              })
          }))
      )
      .then(data => {
        map.addLayer({
          id: 'clusters',
          type: 'symbol',
          source: 'objects',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 16,

            'icon-image': "plum",
            'icon-size': 0.6,
          },           
          paint: {
            "text-color": "#fff"
          }
        });

      });
       
      map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'objects',
        filter: ['!', ['has', 'point_count']],
        paint: {
          //'circle-color': ["rgb",  ["get", "entity"], '#c32fa0'],
          'circle-color': [
            'match',
            ['get', 'entity'],
            'Practice',
            '#000000',
            /* other */ '#c32fa0'
          ],
          'circle-radius': 10,
          'circle-stroke-width': 0.5,
          'circle-stroke-color': '#fff'
        }
      });

      map.addLayer({
        id: 'unclustered-count',
        type: 'symbol',
        source: 'objects',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        },
        paint: {
          "text-color": "#fff"
        }
      });

      map.on('click', 'clusters', function (e) {
        var features = map.queryRenderedFeatures(e.point, {
          layers: ['clusters']
        });
        
        var features = map.queryRenderedFeatures(e.point, { layers: ['clusters'] });
        var clusterId = features[0].properties.cluster_id,
        point_count = features[0].properties.point_count,
        clusterSource = map.getSource('objects');

        if (map.getZoom() > 6.5) {
          clusterSource.getClusterLeaves(clusterId, point_count, 0, function(err, aFeatures){
            _this.initDialog(e,  aFeatures, map);
          });
        } else {


          map.getSource('objects').getClusterExpansionZoom(
            clusterId,
            function (err, zoom) {
              if (err) return;
               
              map.easeTo({
                center: features[0].geometry.coordinates,
                zoom: zoom
              });
            }
          );
        }

      });

      map.on('click', 'unclustered-point', function (e) {
        _this.initDialog(e,  e.features, map);
      });

      map.on('mouseenter', 'clusters', function () {
        map.getCanvas().style.cursor = 'pointer';
      });

      map.on('mouseleave', 'clusters', function () {
        map.getCanvas().style.cursor = '';
      });

      map.on('mouseenter', 'unclustered-point', function () {
        map.getCanvas().style.cursor = 'pointer';
      });

      map.on('mouseleave', 'unclustered-point', function () {
        map.getCanvas().style.cursor = '';
      });

    });
  }


  initDialog(event, features, map) {
    let dialog = null;

    let dialogTemplate = document.querySelector("#map-dialog-template");
    let dialogEl = null;

    const div = document.createElement('div');
    div.innerHTML = dialogTemplate.innerHTML;
    dialogEl = div.firstElementChild;
    dialogEl.classList.add("current-map-dialog");
    document.body.appendChild(dialogEl);


    let elements = [...new Set(features.map(x => x.properties.id))];
    var ids = "";

    elements.forEach(function(element) {
      ids += "ids[]=" + element + "&"
    })


    dialog = new MDCDialog(dialogEl);

    dialog.open();

    getScript("/" + window.locale + "/map.js?" + ids);

    dialog.listen('MDCDialog:closed', function() {
      dialog.root.remove();
    });
   
  }

  focusCountry(countryAlpha3) {

    if (map !== undefined && map != null) {
      if (countryAlpha3.length > 0){
      
        map.setFilter('country-boundaries', [
          "in",
          "iso_3166_1_alpha_3",
          countryAlpha3
        ]);
      
        var coords = countries_bounds[countryAlpha3];
        map.fitBounds([[coords.sw.lon, coords.sw.lat],[coords.ne.lon, coords.ne.lat]], {
          padding: 20
        });
      } else {
        map.setFilter('country-boundaries', false);
      }
    }
  }

}

const getScript = url => new Promise((resolve, reject) => {
  const script = document.createElement('script')
  script.src = url
  script.async = true

  script.onerror = reject

  script.onload = script.onreadystatechange = function() {
    const loadState = this.readyState

    if (loadState && loadState !== 'loaded' && loadState !== 'complete') return

    script.onload = script.onreadystatechange = null

    resolve()
  }

  document.head.appendChild(script)
})

